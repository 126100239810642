<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 18:46:35
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-29 17:19:03
-->
<template>
    <div>
        <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" height="550px" width="940px">
        </Echart>
    </div>
</template>

<script>
    // http://datav.aliyun.com/portal/school/atlas/area_selector
    import Echart from "@/common/echart";
    import {
        getRoudom
    } from "@/utils";
    export default {
        // 湖南省区域地图，和实际的湖南地图一致，湖南 14 个地州都是按地图上 面的形状展示。此图只需要 显示各地州的专业技术人员男性、女性人数以及总人数
        data() {
            return {
                options: {},
            };
        },
        components: {
            Echart,
        },
        props: {
            mapData: {
                type: [Array, Object],
                default: () => [],
            },
            originData: {
                type: [Array, Object],
                default: () => [],
            },
        },
        watch: {
            originData: {
                handler(originData) {
                    console.log(originData);
                    this.$echarts.registerMap('湖南', this.mapData);

                    this.options = {
                        // title: {
                        //     text: '四色预警',
                        //     x: 'center'
                        // },

                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        grid: {
                            top: '5%',
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        dataRange: {
                            min: 0,
                            max: 500,
                            text: ['高', '低'],
                            realtime: true,
                            calculable: true,
                            color: ['orangered', 'yellow', 'green']
                        },
                        series: [{
                            name: '学习人数',
                            type: 'map',
                            map: '湖南',
                            geoIndex: 1,
                            mapLocation: {
                                y: 50,
                                x: 100
                            },
                            roam: true,
                            zoom: 1.1,
                            hoverAnimation: true,
                            label: {
                                normal: {
                                    show: true,
                                    formatter(params) {
                                        console.log(params.data);

                                        if(params.data){
                                            return `{tline|${params.data ? params.data.name : '湖南市'}}\n{tline|${'男:'+ (params.data.manCount || 0) +'人'}}\n{tline|${'女:'+ (params.data.womanCount || 0) +'人'}}\n{fline|${'总:'+ (params.data.totalCount || 0) +'人'}}`;
                                        }else {
                                            return `{tline|${params.data ? params.data.name : '暂无数据'}}\n{tline|${'男:'+ (0) +'人'}}\n{tline|${'女:'+ (0) +'人'}}\n{fline|${'总:'+ (0) +'人'}}`;
                                        }
                                    },
                                    position: 'top',
                                    backgroundColor: 'rgba(17,25,38,.2)',
                                    padding: [5, 5],
                                    borderRadius: 3,
                                    lineHeight: 25,
                                    color: 'white',
                                    rich: {
                                        pic: {
                                            // backgroundColor: {
                                            //     image: require('@/assets/snow.png') // 这是vue写法，不是的按原来的写就行
                                            // },
                                            width: 30,
                                            height: 30,
                                            align: 'center',
                                        },
                                        fline: {
                                            color: '#FFDC6C',
                                            align: 'center'
                                        },
                                        tline: {
                                            color: 'white',
                                            align: 'center'
                                        },
                                    },
                                },
                                emphasis: {
                                    show: true,
                                },
                            },
                            itemStyle: {
                                normal: {
                                    areaColor: "rgba(255,255,255,0.8)",
                                    borderColor: "#fff",
                                    borderWidth: 3,
                                    shadowColor: '#4341c9'
                                },
                                emphasis: {
                                    areaColor: "#87ceed"
                                }
                            },
                            data: originData.map(i => {
                                i.name = i.cityName
                                i.value = i.totalCount
                                return i
                            })
                        }],

                    }
                },
                immediate: true,
                deep: true,
            },
        },
        methods: {
            getRoudom
        },
    };
</script>