<template>
  <div class="host-body">
    <div class="optimizedDecision-left left">
      <left_1 ref="left_1" :originData="left_1" />
      <left_2 ref="left_2" :originData="left_2" />
      <left_3 ref="left_3" :originData="left_3" />
    </div>

    <div class="center">
      <div class="bottom">
        <center_1 ref="center_1" :leftData="leftData" :rightData="rightData" />
      </div>
    </div>

    <div class="ai">
      <Map :mapData="hunanmap" :originData="mapList" />
    </div>

    <div class="optimizedDecision-left right">
      <right_1 ref="right_1" :originData="right_1" />
      <right_2 ref="right_2" :originData="right_2" />
      <right_3 ref="right_3" :originData="right_3" />
    </div>
  </div>
</template>

<script>
  import drawMixin from "@/utils/drawMixin";
  import pageReloadMixin from "@/utils/pageReloadMixin";

  import left_1 from "./components/left_1/index";
  import left_2 from "./components/left_2/index";
  import left_3 from "./components/left_3/index"
  import center_1 from "./components/center_1/index"
  import right_1 from "./components/right_1/index"
  import right_2 from "./components/right_2/index"
  import right_3 from "./components/right_3/index"

  import Map from "./components/map/index"

  import hunanmap from "@/utils/hunanmap";

  export default {
    mixins: [drawMixin, pageReloadMixin],
    data() {
      return {
        hunanmap,
        left_1: {},
        left_2: {},
        left_3: {},
        leftData: [],
        rightData: [],
        right_1: [],
        right_2: [],
        right_3: [],
        mapData: [],
        mapList: []
      };
    },
    mounted() {
      this.pageInit()
    },
    methods: {
      async pageInit() {
        this.$blockInit({
          url: 'GetCourseStudySort',
        }).then(res => {
          this.left_1 = res.data
        })

        this.$blockInit({
          url: 'GetCourseReport',
        }).then(res => {
          this.left_2 = res.data
        })

        this.$blockInit({
          url: 'GetCourseSort',
        }).then(res => {
          this.left_3 = res.data
        })

        this.$blockInit({
          url: 'GetTodyDataReport',
        }).then(res => {
          this.leftData = res.data
        })

        this.$blockInit({
          url: 'GetAreaTrainSort',
        }).then(res => {
          this.rightData = res.data
        })

        this.$blockInit({
          url: 'GetCityMemberReport',
        }).then(res => {
          this.right_1 = res.data
        })

        this.$blockInit({
          url: 'GetMemberAgeRatio',
        }).then(res => {
          this.right_2 = res.data
        })

        this.$blockInit({
          url: 'GetWorkUnitTrainSort',
        }).then(res => {
          this.right_3 = res.data
        })

        //地图
        this.$blockInit({
          url: 'GetProvinceProvince',
        }).then(res => {

          this.mapList = res.data
        })
      },
      handleGoTo(path) {
        this.$router.push(path)
      },
    },
    components: {
      left_1,
      left_2,
      left_3,
      center_1,
      right_1,
      right_2,
      right_3,
      Map
    }
  };
</script>

<style lang="scss" scoped>
  .host-body {
    .left {
      width: 470px;
      position: absolute;
      left: 10px;
      bottom: 40px;
      z-index: 10;
    }

    .right {
      width: 470px;
      position: absolute;
      right: 10px;
      bottom: 40px;
      z-index: 10;
    }

    .ai {
      text-align: center;
      width: calc(100% - 980px);
      height: 480px;
      line-height: 480px;
      position: absolute;
      bottom: 100px;
      z-index: 10;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .center {
      width: calc(100% - 960px);
      position: absolute;
      top: 120px;
      z-index: 10;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    .router {
      margin: 10px auto;
      left: 0;
      right: 0;
      top: 160px;
      width: 600px;
      display: flex;
      position: absolute;

      div {
        margin: 5px;
        cursor: pointer;
        font-size: 24px;
        text-align: center;
        line-height: 48px;
        flex: 1;
        height: 48px;
        background: rgba(44, 158, 249, 0.3500);
        border: 1px solid rgba(255, 255, 255, 0.2200);
        border: 1px solid transparent;

        &:hover,
        &.active {
          background: #2E83DF;
          border: 1px solid #4BDBFF;
          box-shadow: 0px 4px 10px 0px rgba(45, 94, 147, 0.3800);
        }
      }
    }
  }
</style>