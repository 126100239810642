<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 18:46:35
 * @LastEditors: guozong
 * @LastEditTime: 2022-09-10 18:40:25
-->
<template>
  <div>
    <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" height="200px" width="420px"></Echart>
  </div>
</template>

<script>
  import Echart from "@/common/echart";
  import {
    getRoudom
  } from "@/utils";
  export default {
    data() {
      return {
        options: {},
      };
    },
    components: {
      Echart,
    },
    props: {
      originData: {
        type: [Array, Object],
        default: () => [],
      },
    },
    watch: {
      originData: {
        handler(originData) {

          let sum = originData.reduce((a, b) => {
            return a + b.userCount
          }, 0)

          this.options = {
            // title: {
            //   text: 'World Population'
            // },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            // legend: {},
            grid: {
              top: '10%',
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            series: [{
              name: '人数',
              type: 'pie',
              radius: [0, 65],
              center: ['50%', '55%'],
              label: {
                fontSize: 14
              },
              itemStyle: {
                borderRadius: 8,
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                      color: 'white',
                      fontSize: 16
                    },
                    formatter: function (v) { //让series 中的文字进行换行
                    console.log(v);
                      //文字中遇到空格就换行,让value换行可以先把value变成字符串再slice操作
                      let text = v.data.name + "\n " + ' ' + v.data.name1;
                      return text;
                    }
                  }
                }
              },
              data: originData.map(i => {
                return {
                  value: i.userCount,
                  name: `${i.ageRange}`,
                  name1: `${i.userCount}人${((i.userCount/sum)*100).toFixed(1)}%`,
                }
              })
            }]
          }
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      getRoudom
    },
  };
</script>