<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 17:17:48
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 15:21:18
-->
<template>
  <div class="container">
    <div class="d-flex pt-2 pb-2 pl-2">
      <div class="header-optimizedDecision">
        年度课程汇总
      </div>
    </div>

    <div class="content">
      <p class="d-flex jc-between ai-center">
        <span>课程总数</span>

        <span class="text-primary">{{originData.totalCount}}门</span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>专业课程</span>

        <span class="text-primary">{{originData.zyCount}}门</span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>当天播放次数</span>

        <span class="text-primary">{{originData.todayPlayCount}}次</span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>昨天播放次数</span>

        <span class="text-primary">{{originData.yestodayPlayCount}}次</span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>本周播放次数</span>

        <span class="text-primary">{{originData.thisWeekPlayCount}}次</span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>日环比</span>

        <span>环比：

          <span
            :class="[originData.todayIsUp ? 'text-danger':'text-success']">{{(originData.todayRate || 0).toFixed(2)}}%
            {{upOrDown(originData.todayIsUp)}}</span>
        </span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>周环比</span>

        <span>环比：

          <span
            :class="[originData.weekIsUp ? 'text-danger':'text-success']">{{(originData.weekRate || 0).toFixed(2)}}%
            {{upOrDown(originData.weekIsUp)}}</span>
        </span>
      </p>

    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      upOrDown(type) {
        if (type) return '↑'
        return '↓'
      }
    },
    props: {
      originData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  $box-height: 260px;

  .container {
    height: $box-height;
    position: relative;

    .content {
      padding: 0 30px;

      p {
        color: white;
        font-size: 20px;
        font-weight: 900;
        text-align: left;
        padding: 5px;
      }
    }
  }
</style>