<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 19:03:03
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 15:20:25
-->
<template>
  <div>
    <div class="container optimizedDecision-bottom">
      <div class="item">
        <div class="d-flex pt-2 pb-2 pl-2">
          <div class="header-optimizedDecision">
            实时数据
          </div>
        </div>

        <div class="content">
          <p class="d-flex jc-between ai-center">
            <span>今日注册人数</span>

            <span class="text-warning">{{leftData.registerCount || 0}}人</span>
          </p>
          <p class="d-flex jc-between ai-center">
            <span>今日报名人数</span>

            <span class="text-warning">{{leftData.trainCount || 0}}人</span>
          </p>
          <p class="d-flex jc-between ai-center">
            <span>今日学习人数</span>

            <span class="text-warning">{{leftData.studyCount || 0}}人</span>
          </p>
          <p class="d-flex jc-between ai-center">
            <span>今日毕业人数</span>

            <span class="text-warning">{{leftData.examCount || 0}}人</span>
          </p>
        </div>

        <div class="d-flex jc-center">
          <Chart :originData="leftData" />
        </div>
      </div>
      <div class="item">
        <div class="d-flex pt-2 pb-2 pl-2">
          <div class="header-optimizedDecision">
            年度区域排名
          </div>
        </div>

        <div class="d-flex jc-center">
          <dv-scroll-ranking-board :config="config" style="width:80%;height:250px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    parseTimeTwo
  } from '@/utils'

  import Chart from "./chart.vue";

  export default {
    components: {
      Chart,
    },
    data() {
      return {
        originData: [],
        config: {}
      }
    },
    methods: {
      parseTimeTwo
    },
    props: {
      leftData: {
        type: [Object, Array],
        default: () => ({}),
      },
      rightData: {
        type: [Object, Array],
        default: () => ({}),
      },
    },

    watch: {
      rightData: {
        handler(val) {
          this.config = {
            data: val.map(item => {
              return {
                name: item.areaName,
                value: item.trainCount
              }
            }),
            unit: ''
          }
        },
        deep: true,
        immediate: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  $box-height: 360px;

  .container {
    height: $box-height;
    display: flex;
    padding: 20px 0px 0 30px;

    .content {
      padding: 0 30px;

      p {
        color: white;
        font-size: 14px;
        font-weight: 900;
        text-align: left;
        padding: 2px 5px;
      }
    }

    .item {
      flex: 1;

      .info {
        .a {
          font-size: 24px;
          padding-left: 100px;
          color: white;
          width: 400px;
          height: 80px;
          line-height: 80px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("~@/assets/optimizedDecision/icon-1-5.png");
        }

        .b {
          font-size: 24px;
          padding-left: 100px;
          color: white;
          width: 400px;
          height: 80px;
          line-height: 80px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("~@/assets/optimizedDecision/icon-1-6.png");
        }

        .c {
          font-size: 24px;
          padding-left: 100px;
          color: white;
          width: 400px;
          height: 80px;
          line-height: 80px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url("~@/assets/optimizedDecision/icon-1-7.png");
        }
      }
    }

  }

  .choose {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;


    // .div {
    //   font-size: 30px;
    //   color: #fff;
    //   width: 306px;
    //   height: 75px;
    //   line-height: 75px;
    //   text-align: center;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: left center;
    //   background-image: url("~@/assets/analyticalPrediction/screen_1_tab.png");
    // }
  }
</style>