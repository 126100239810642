<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 17:17:48
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 15:27:47
-->
<template>
  <div class="container">
    <div class="d-flex pt-2 pb-2 pl-2">
      <div class="header-optimizedDecision">
        在学课程一览
      </div>
    </div>

    <div class="content">
      <div class="d-flex jc-between ai-center">
        <dv-scroll-ranking-board :config="config" style="width:100%;height:200px" />
      </div>

      <p class="d-flex jc-between ai-center">
        <span>拿证人均学习天数</span>

        <span class="text-warning">{{(originData.examPerDays || 0).toFixed(2)}}天/人</span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>年度人均学习时长</span>

        <span class="text-info-1">{{(originData.studyPerHours || 0).toFixed(2)}}分钟/人</span>
      </p>

      <p class="d-flex jc-between ai-center">
        <span>年度人均考试分数</span>

        <span class="text-danger">{{(originData.examPerScore || 0).toFixed(2)}}/分</span>
      </p>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        config: {}
      };
    },
    props: {
      originData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    watch: {
      originData: {
        handler(val) {
          this.config = {
            data: val.courseSortList.map(item => {
              return {
                name: item.courseName,
                value: item.studyCount
              }
            }),
            unit: ' 人在学'
          }
        },
        deep: true,
      }
    }
  };
</script>

<style lang="scss" scoped>
  $box-height: 400px;

  .container {
    height: $box-height;
    position: relative;

    .content {
      padding: 0 30px;

      p {
        color: white;
        font-size: 20px;
        font-weight: 900;
        text-align: left;
        padding: 5px;
      }
    }
  }
</style>