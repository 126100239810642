<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-04 11:01:22
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 15:20:51
-->
<template>
  <div class="container ">
    <div class="d-flex pt-2 pb-2 pl-2">
      <div class="header-optimizedDecision">
        专业技术人员
      </div>
    </div>
    <div class="d-flex jc-center">
      <dv-scroll-board :config="config" style="width:80%;height:220px" />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        config: {}
      }
    },
    props: {
      originData: {
        type: [Object, Array],
        default: () => ({}),
      },
    },
    watch: {
      originData: {
        handler(val) {
          this.config = {
            header: ['市直', '男性', '女性', '合计'],
            data: val.map(i => {
              return [i.cityName, i.manCount, i.womanCount, i.totalCount]
            })
          }
        },
        deep: true,
        immediate: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  $box-height: 280px;

  .container {
    height: $box-height;
  }
</style>